@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat Bold"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Light";
  src: local("Montserrat Light"),
    url("./fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Regular";
  src: local("Montserrat Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

* {
  font-family: "Montserrat Regular";
}

body {
  margin: auto;
}

.footer {
  /* position: fixed;
  bottom: 20px; */
  width: 100%;
  /* text-align: center; */
  border-top: 1px solid #000; /* Optional: Add a top border */
}

.header-logo {
  margin-bottom: 10px;
}


@media print {
  
  @page {
    size: Letter;
    margin-left: 0.5in; /* Adjust margins as needed */
    margin-right: 0.5in; /* Adjust margins as needed */
    margin-top: 0.5;
    margin-bottom: 0;
    /* scale: '100%'; */
  }
  
  .print-container {
    margin: 0 !important;
    padding: 0 !important;
    height: 10in; /* Adjust this value to fit your content */
    /* background-color: cadetblue; */
    page-break-inside: avoid;
    break-inside: avoid;
  }

  html, body {
    height: 100vh; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    /* background-color: cadetblue; */
  }

  .header-logo {
    margin-bottom: 0.2in;
  }

  .footer {
    position: absolute;
    /* position: fixed; */
    /* left: 0.5in;  Align footer to the left */
    /* right: 0.5in; Stretch the footer to the right */
    width: 100%; 
    /* margin: 0 !important;
    padding: 0 !important; */
    bottom: 20px;
    overflow: visible;
    max-width: calc(100% - 0.5in); 
    border-top: 1px solid #000; /* Optional: Add a top border */
  }
  @supports (-webkit-backdrop-filter: none) {
    .footer {
      position: sticky;
      margin-top: 20px;
    }
    .print-container {
      zoom: 90%;
    }
  }

}